* {
  font-family: "Figtree", sans-serif !important;
  
}

a {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  text-decoration: none;
  color: inherit;
}

#click {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  text-decoration: revert;
  color: blue;
}

a:hover {
  color: inherit;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.card_hover:hover {
  box-shadow: 0px 4px 6px 1px #80808073 !important;
}

input.quiz:focus {
  outline: none;
  box-shadow: 2px 2px 0px 1px #fbb0346b;
}

.slick-track {
  display: flex !important;
  align-items: center;
}


*:focus {
  outline: 0;
  outline: none;
}



img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.react-multi-carousel-dot button {
  background-color: #c8d3f0 !important;
  border: none !important;
}

.react-multi-carousel-dot--active button {
  background-color: #4d71d0 !important;
}

.react-multi-carousel-dot-list {
  right: 22px !important;
  bottom: 0px !important;
}

input[type="checkbox"] {
  accent-color: red !important;
}

.slick-prev,
.slick-next {
  display: none !important;
}



.react-multiple-carousel__arrow {
  z-index: 1 !important;
}

.awssld__content {
  background-color: transparent !important;
}

.css-1jhlept-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: 350px !important;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  min-width: 230px !important;
}

.css-1ab2xsx {
  min-width: 230px !important;
}

:root {
  --animate-duration: 2000ms;
  --animate-delay: 2s;
}

.cardImg {
  position: relative;
  background-color: white;
}

.inPress {
  text-align: center;
  width: 80%;
  margin: auto;
  position: relative;
}

.inPress::after {
  content: "";
  height: 2px;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-110%, -50%);
  background-color: black;
}

.inPress::before {
  content: "";
  height: 2px;
  width: 40%;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(110%, -50%);
  background-color: black;
}

.ServiceManText {
  background: linear-gradient(269.02deg, #9ae7ff -1.64%, #ceff91 80.56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.dilHaiHindustani_text {
  background: -webkit-linear-gradient(0deg, #df7d1b 42%, #ffffff 50%, #0e8204 65%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.slide-1:hover {
  filter: none;
}

.flexDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
}

#slide {
  filter: grayscale(1);
}

#slide:hover {
  filter: none;
}

.AccountType {
  filter: none;
  border-bottom: 3px solid #4d71d0;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #4d71d0;
  margin: 5px 10px;
  justify-content: center;
  color: #4d71d0;
}

.AccountType_notClicked {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  margin: 5px 10px;
  filter: grayscale(1);
  color: #6c717f;
}

.carousel .control-dots .dot {
  background-color: #4d71d0 !important;
  bottom: 0;
}

.carousel .control-dots {
  margin: 0 !important;
  bottom: -4px !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid black !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid black !important;
}

.app-slider-img {
  max-height: 90px !important;
}


#bgShadeHover {
  margin: 10px 0px;
  padding: 12px;
  display: flex;
  width: 80%;
  position: relative;
}

#bgShade {
  margin: 10px 0px;
  padding: 12px;
  display: flex;
  background-color: #e7f7e7;
  cursor: pointer;
  width: 80%;
  position: relative;
}

#featureTitleSelected {
  color: #393f50;
  font-weight: bold;
}

#featureTitle {
  color: #393f50;
}

#featureContSelected {
  color: black;
}

#featureCont {
  color: #6c717f;
}

.lty-playbtn {
  display: none;
}

.yt-lite {
  border-radius: 10px;
}

#youtubePlayIcon3 {
  display: none;
}

#youtubePlayIcon3Box:hover>#youtubePlayIcon3 {
  display: block;
  transition: all ease-in 1s;
}

.css-dqr9h-MuiRating-label {
  bottom: 33% !important;
}

.react-multi-carousel-list {
  border: "2px solid yellow" !important;
}

.css-c5xpwg-MuiPaper-root-MuiDrawer-paper {
  width: 100% !important;
}

.css-pqizal {
  width: 100% !important;
}

* {
  --animate__fadeInDown: {
    delay: "3s";
  }
}

.horizontalScroll {
  position: relative;
}

.horizontalScroll::after {
  content: "";
  height: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}

.react-multi-carousel-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.react-multi-carousel-list {
  height: 450px;
}

.react-multi-carousel-dot-list {
  right: 22px !important;
  bottom: 0px !important;
}

@media screen and (max-width: 450px) {
  .react-multi-carousel-list {
    height: 350px;
  }
  .testiCards {
    height: fit-content !important;
    margin-top: 5px !important;
    align-items: center;
  }
  #ApostropheImgInverted {
    height: 30px;
    width: 30px;
    bottom: 35px !important;
  }
  .testiHeading {
    display: none;
  }
}

.react-multi-carousel-dot button {
  background-color: #c8d3f0 !important;
  border: none !important;
}

.react-multi-carousel-dot--active button {
  background-color: #4d71d0 !important;
}

.ellipisClass {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipisClassContent {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogCard:hover {
  transition: 0.3s;
  box-shadow: 0px 4px 8px #00000026;
}

#card {
  transition: all 1s;
}

#cardText {
  opacity: 0;
  border-radius: 12px;
  background: #3366e899;
  box-shadow: 0px 4px 24px 1px rgba(35, 16, 94, 0.25);
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#card:hover>#cardText {
  opacity: 1;
  display: flex;
  backdrop-filter: blur(2px);
}

.css-1v2lia3-MuiGrid-root {
  width: 120px !important;
}

.dots-loading-container {
  position: fixed;
  inset: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  z-index: 11;
  background: #ffffff82;
}


.dots-loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.dots-loading div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: gray;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.dots-loading div:nth-child(1) {
  left: 8px;
  animation: b1 0.6s infinite;
}

.dots-loading div:nth-child(2) {
  left: 8px;
  animation: b2 0.6s infinite;
}

.dots-loading div:nth-child(3) {
  left: 32px;
  animation: b2 0.6s infinite;
}

.dots-loading div:nth-child(4) {
  left: 56px;
  animation: b3 0.6s infinite;
}

.teamsCard-dots>.slick-dots {
  bottom: -25px !important;
}

.investorIcon-dots>.slick-dots {
  bottom: 0 !important;
}

@keyframes b1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes b3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes b2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.an1 {
  height: 1.2em;
  width: 1.2em;
  vertical-align: middle;
}

#onlyHrefBlog {
  color: #0d6efd;
}

#onlyHrefRedirectionBlog {
  padding: 8px 12px !important;
  color: #1976d2;
  border-radius: 4px;
  background-color: #edf0fa;
}
